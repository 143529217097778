
@media print {
    header {
        display: none;
    }
    .inputs {
        display: none;
    }
    .tracingList {
        margin-top: 50px !important;
    }
}

header {
    width: 80vw;
    margin: 0 auto;
}

.inputs {
    width: 80vw;
    margin: 0 auto;
}

.hidden {
    display: none;
}

.formRow {
    display: flex;
    margin: 7px 0;
}

.formRow input {
    margin: 0 14px;
}

.formRow button {
    margin: 4px 16px 0 0;
    padding: 3px 8px;
    border: 1px solid #000;
    background-color: azure;
}

.ranTable {
    border-collapse: collapse;
    margin: 0 auto;
}

.ranTable td {
    text-align: center;
    font-size: 20pt;
    border: 1px solid #000;
    height: 40pt;
    width: 40pt;
}

.tracingList {
    width: 90vw;
    margin: 24px auto 0 auto;
    font-family: Print Dashed, sans-serif;
}

.tracingRow {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    font-size: 48px;
    line-height: 42px;
    height: 36px;
    margin: 24px 0;
    padding: 0;
    display: flex;
    position: relative;
}

.tracingList hr {
    position: absolute;
    top: -6px;
    height: 0;
    border-top: 1px dashed #999;
    border-bottom: 0;
    width: 100%;
}

.tracingRow span {
    flex: 1;
    text-align: center;
}